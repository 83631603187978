import { formatNumber, metersToMiles } from '../number';
import { pluralizeUsingS } from '../string';

export interface GetNearbyPlaceDistanceTextConfig {
  distanceUnit: 'meters' | 'miles';

  // If specified, will use this as the label that comes after the distance
  // number (e.g. 10 <label>). If not specified, will use the distance unit
  // string itself.
  distanceUnitLabel?: string;
}

const defaultGetNearbyPlaceDistanceTextConfig: GetNearbyPlaceDistanceTextConfig =
  {
    distanceUnit: 'meters',
  };

/**
 * Given nearby place data, return a string representing the place's distance.
 *
 * Can optionally choose to provide distance as either meters (default) or
 * miles.
 */
export default function getNearbyPlaceDistanceText(
  distanceInMeters: number,
  config = defaultGetNearbyPlaceDistanceTextConfig
) {
  const { distanceUnit, distanceUnitLabel } = {
    ...defaultGetNearbyPlaceDistanceTextConfig,
    ...config,
  };

  const distanceNumber =
    distanceUnit === 'meters'
      ? Number(distanceInMeters)
      : metersToMiles(Number(distanceInMeters));
  const distanceStr = formatNumber(distanceNumber, {
    decimalPlaces: 1,
  });

  const distanceUnitStr = distanceUnit === 'meters' ? 'meter' : 'mile';
  const pluralizedDistanceUnitStr = pluralizeUsingS(
    distanceUnitStr,
    Number(distanceStr)
  );

  return `${distanceStr} ${distanceUnitLabel ?? pluralizedDistanceUnitStr}`;
}
