/**
 * Given a word and a count, pluralize the word by adding 's' at the end if the
 * count is not 1.
 *
 * If the count is omitted or NaN, will pluralize the word always.
 */
export default function pluralizeUsingS(word: string, count?: number): string {
  if (!word) {
    return '';
  }

  return count === undefined || count !== 1 ? `${word}s` : word;
}
