import { PropertyV2 } from '../../api/property/types';

export interface GetPropertyAttributesConfig {
  // If 'property', will only consider 'attr_' attributes
  // If 'flat', will only consider 'fttr_' attributes
  // If 'all', will consider both 'attr_' and 'fttr_' attributes
  whatToInclude?: 'property' | 'flat' | 'all';

  // If a limit is specified, will return after this many matches
  limit?: number;
}

const defaultConfig: GetPropertyAttributesConfig = {
  whatToInclude: 'all',
};

const ATTRIBUTE_BLACKLIST = ['fttr_bed_size'];

const ATTRIBUTE_ORDER: Array<keyof PropertyV2> = [
  'fttr_pet_friendly',
  'attr_furnished',
  'attr_concierge',
  'attr_onsite_maintenance',
  'attr_onsite_management',
  'attr_parking',
  'zero_deposit',
];

/**
 * Determine the 'boolean' value string attributes. Certain attributes have
 * string values and showing them require more than just checking for falsy.
 */
function stringAttributeToBoolean(
  key: keyof PropertyV2,
  value: string
): boolean {
  if (key === 'attr_furnished') {
    return value === 'Furnished' || value === 'Flexible';
  }

  return false;
}

/**
 * Given a property data object, return boolean property attributes that are
 * true.
 *
 * This is helpful when generating tags related to a property, for example.
 * */
export default function getPropertyAttributes(
  property: PropertyV2,
  config: GetPropertyAttributesConfig = defaultConfig
): string[] {
  const { whatToInclude, limit } = {
    ...defaultConfig,
    ...config,
  };

  const propertyAttributes: Array<keyof PropertyV2> = [];

  const validPrefixes: string[] = [];
  if (whatToInclude === 'property') {
    validPrefixes.push('attr_');
  } else if (whatToInclude === 'flat') {
    validPrefixes.push('fttr_');
  } else {
    validPrefixes.push('attr_', 'fttr_');
  }

  const propertyKeys = Object.keys(property) as unknown as Array<
    keyof PropertyV2
  >;

  // Sort the keys according to our pre-defined order
  propertyKeys.sort((a, b) => {
    const orderIndexA = ATTRIBUTE_ORDER.indexOf(a);
    const orderIndexB = ATTRIBUTE_ORDER.indexOf(b);

    if (orderIndexA === -1 && orderIndexB === -1) {
      // If neither A nor B is found in the ordering array, we keep their
      // original ordering

      return 0;
    }

    if (orderIndexA > -1 && orderIndexB === -1) {
      // If A is found in the ordering array but not B, we put A before B

      return -1;
    }

    if (orderIndexA === -1 && orderIndexB > -1) {
      // If B is found in the ordering array but not A, we put B before A

      return 1;
    }

    // If both A and B are found in the ordering array, we compare their indices
    // ascendingly

    return orderIndexA - orderIndexB;
  });

  let i = 0;
  while (
    (!limit && i < propertyKeys.length) ||
    (limit && propertyAttributes.length < limit && i < propertyKeys.length)
  ) {
    const propertyAttributeKey = propertyKeys[i];

    const propertyAttributeValue = property[propertyAttributeKey];

    const prefix = propertyAttributeKey.slice(0, 5);

    if (
      validPrefixes.includes(prefix) &&
      !ATTRIBUTE_BLACKLIST.includes(propertyAttributeKey) &&
      (typeof propertyAttributeValue === 'string'
        ? stringAttributeToBoolean(propertyAttributeKey, propertyAttributeValue)
        : propertyAttributeValue === true)
    ) {
      propertyAttributes.push(propertyAttributeKey);
    }

    i += 1;
  }

  return propertyAttributes;
}
