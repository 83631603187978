import getNearbyPlaceDistanceText from './getNearbyPlaceDistanceText';
import { NearbyPlace } from '../../models/nearbyPlaces';

export interface GetNearbyPlacesTextConfig {
  distanceUnit: 'meters' | 'miles';
}

const defaultGetNearbyPlacesTextConfig: GetNearbyPlacesTextConfig = {
  distanceUnit: 'meters',
};

/**
 * Given an array of nearby places data, return a string detailing each place's
 * name and its distance.
 *
 * Can optionally choose to provide distance as either meters (default) or
 * miles.
 */
export default function getNearbyPlacesText(
  places: NearbyPlace[],
  config = defaultGetNearbyPlacesTextConfig
): string {
  const { distanceUnit } = {
    ...defaultGetNearbyPlacesTextConfig,
    ...config,
  };

  return places
    .map((place) => {
      const distanceStrWithUnit = getNearbyPlaceDistanceText(
        place.place_distance_m ?? 0,
        {
          distanceUnit,
        }
      );

      return `${place.place_data.name} (${distanceStrWithUnit})`;
    })
    .join(', ');
}
