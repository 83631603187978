import { LatLngLiteral } from '../googleMaps/gcpMaps/types';
import { OrgFile } from '../orgs/types';
import { FlatDetailsCategory } from './models';

export enum SearchPropertiesSortValueV2 {
  RECOMMENDED = 'Recommended',
  PRICE_HIGH_TO_LOW = 'Price: High to Low',
  PRICE_LOW_TO_HIGH = 'Price: Low to High',
}

export interface SearchPropertiesOpts {
  center: { lat: number; lng: number };
  radius: number;
  flatsFilter?: {
    bedroomCount?: number;
    maxRent?: number;
  };
}

export interface DevelopmentInDbV2 {
  development_id: number;

  is_public: boolean;

  title: string;
  owner?: string;
  manager?: string;
  organization_id: number;

  geography: string;
  post_code?: string;
  address?: string;
  city?: string;

  units_count?: number;
  development_type?: string;

  img_ids?: string[];
  img_data?: ImageData;

  // Metadata

  completion_status?: string;
  completion_date?: Date;
}

export interface DevelopmentWithGeoJson extends DevelopmentInDbV2 {
  geographyJson: any;
}

export interface DevelopmentActivityInDb {
  activity_id: number;
  development_id: number;
  user_id: number;
  activity_type: string;
  activity_date: Date;
}

export interface FlatInDb {
  // ----- Metadata ----- //

  flat_id: number;
  development_id: number;
  organization_id: number;

  // ----- General ----- //

  flat_title: string;

  // ----- Fees ----- //

  rent_pcm?: number | null;

  // ----- Images ----- //

  flat_img_thumbnail_id: string; // UUID

  // ----- Attributes ----- //

  flat_size?: number;
  bedrooms_count?: number;
}

export interface FlatActivityInDb {
  activity_id: number;
  flat_id: number;
  user_id: number;
  activity_type: string;
  activity_date: Date;
}

// ---------- Map polygon ---------- //

export interface MapPolygon {
  type: string;

  // The first number is longitude, the second number is latitude
  coordinates: [number, number][][] | [number, number][][][];
}

// ---------- Nearby places ---------- //

export enum NearbyPlaceType {
  SUBWAY_STATION = 'subway_station', // Includes London Underground (tube)
  TRAIN_STATION = 'train_station',
  LIGHT_RAIL_STATION = 'light_rail_station', // Includes DLR
  BUS_STATION = 'bus_station',
  STORE = 'store',
  SUPERMARKET = 'supermarket',
  SCHOOL = 'school',
}

export interface DevelopmentsNearbyPlace {
  development_id: number;
  place_type: NearbyPlaceType;
  place_data: {
    name: string;
    icon: string;
    geometry: { location: LatLngLiteral };
  };
  place_distance_m: number | null;
  place_id: string;
}

// ---------- Flat ---------- //

export enum FlatTypeForSearchPurpose {
  PRIVATE_STUDIO,
  PRIVATE_ONE_BEDROOM,
  PRIVATE_TWO_BEDROOM,
  PRIVATE_THREE_BEDROOM,
  PRIVATE_FOUR_BEDROOM,

  SHARED,
  SHARED_ENSUITE,
}

// ---------- Development ---------- //

export interface DevelopmentBasicAttributes {
  development_id: number;
  organization_id: number;
  title: string;
  owner_id?: number;
  manager_id?: number;
  development_type?: string;
  description_free_text?: string;
  other_notes?: string;
  is_public: boolean;
}

export interface DevelopmentLocationAttributes {
  // This is a PostGIS property. This property should be removed and replaced
  // with something else more "parsable" for API consumers.
  geography: string;

  address?: string;
  postcode?: string;
  city?: string;
  region?: string;
  local_authority?: string;
  area?: string;
  country?: string;
}

export interface DevelopmentContactAttributes {
  development_website?: string;
  development_phone?: string;
  contact_name?: string;
  contact_phone?: string;
  contact_email?: string;
  contact_job_title?: string;
}

export type EpcValue = 'a' | 'b' | 'c' | 'd' | 'e' | 'f' | 'g';

export interface DevelopmentBuildingAttributes {
  attr_concierge?: boolean;
  attr_parcel?: boolean;
  attr_onsite_maintenance?: boolean;
  attr_bike_storage?: boolean;
  attr_parking?: boolean;
  attr_lift?: boolean;
  attr_cctv?: boolean;
  attr_controlled_access?: boolean;
  attr_gym?: boolean;
  attr_pool?: boolean;
  attr_common_area?: boolean;
  attr_entertainment_area?: boolean;
  attr_cinema?: boolean;
  attr_work_area?: boolean;
  attr_library?: boolean;
  attr_wifi?: boolean;
  attr_social_events?: boolean;
  attr_roof_terrace?: boolean;
  attr_communal_garden?: boolean;
  attr_furnished?: string;
  attr_smoke_alarm?: boolean;
  attr_video_door_entry?: boolean;
  attr_usb_port?: boolean;
  attr_epc?: EpcValue;
  attr_onsite_management?: boolean;
  attr_dining_room?: boolean;
  attr_bar?: boolean;
  attr_guest_suites?: boolean;
  attr_heating?: string;
  attr_hot_water?: string;
  attr_bathroom_type?: string;
  attr_swimming_pool?: boolean;
}

export interface DevelopmentFlatAttributes {
  fttr_bed_and_mattress?: boolean;
  fttr_bed_size?: string;
  fttr_built_in_wardrobe?: boolean;
  fttr_drawers?: boolean;
  fttr_sofa?: boolean;
  fttr_coffee_table?: boolean;
  fttr_dining_table_and_chairs?: boolean;
  fttr_tv_stand?: boolean;
  fttr_tv?: boolean;
  fttr_open_plan_kitchen?: boolean;
  fttr_hob_type?: string;
  fttr_fridge?: boolean;
  fttr_oven?: boolean;
  fttr_microwave?: boolean;
  fttr_dishwasher?: boolean;
  fttr_washing_machine?: boolean;
  fttr_dryer?: boolean;
  fttr_smart_meter?: boolean;
  fttr_balcony?: boolean;
  fttr_pet_friendly?: boolean;
  fttr_student_friendly?: boolean;
  fttr_family_friendly?: boolean;
  fttr_armchair?: boolean;
}

export interface DevelopmentFeesAttributes {
  short_rent?: boolean | null;
  min_term?: string | null;
  max_term?: string | null;
  deposit_term?: string | null;
  notice_period?: string | null;
  zero_deposit?: boolean | null;
  break_clause?: string | null;
}

export interface PrimitiveAttributesFilter
  extends Partial<
    Omit<DevelopmentBuildingAttributes, 'attr_epc'> &
      DevelopmentFlatAttributes &
      DevelopmentFeesAttributes
  > {
  // Allow multiple EPC values to be filtered
  attr_epc?: Array<EpcValue>;
}

export enum PropertiesSearchSortType {
  RECOMMENDED = 'RECOMMENDED',
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
}

export interface DevelopmentV2
  extends DevelopmentBasicAttributes,
    DevelopmentLocationAttributes,
    DevelopmentContactAttributes,
    DevelopmentBuildingAttributes,
    DevelopmentFlatAttributes,
    DevelopmentFeesAttributes {
  // TODO: deprecate
  owner?: string;
  manager?: string;

  units_count?: number;
  units_prs_count?: number;

  // ----- Construction ----- //

  completion_status?: 'Complete' | 'Incomplete';
  completion_date?: string | null;

  // ----- Location ----- //

  geographyJson: LatLngLiteral;

  nearby_places?: DevelopmentsNearbyPlace[];

  // ----- Images ----- //

  // The thumbnail is the primary image
  img_thumbnail_id?: string;

  virtual_tour_url?: string;

  // The "front-page" rent of the development. It's the minimum non-null rent
  // across all of its flats.
  rent_pcm: number | null;
}

export interface DevelopmentDetails {
  developments_details_content?: string | null;
}

export interface FlatV2 {
  flat_id: number;
  development_id: number;
  organization_id: number;

  flat_title: string;

  flat_img_ids?: string[];
  flat_img_data?: Record<string, ImageData>;
  flat_floorplan_img_ids?: string[];
  flat_floorplan_img_data?: Record<string, ImageData>;

  rent_pcm: number;
  bedrooms_count: number;
  flat_img_thumbnail_id?: string;

  flat_size?: number;
  flat_category?: FlatDetailsCategory;
  flats_details?: FlatDetails[];
}

export type PropertyV2 = DevelopmentV2 & FlatV2;

export const imageCategory = {
  UNCATEGORIZED: 'UNCATEGORIZED',
  DEVELOPMENT_IMAGE: 'DEVELOPMENT_IMAGE',
  DEVELOPMENT_BROCHURE: 'DEVELOPMENT_BROCHURE',
  DEVELOPMENT_VIDEO: 'DEVELOPMENT_VIDEO',
  FLAT_IMAGE: 'FLAT_IMAGE',
  FLOOR_PLAN_IMAGE: 'FLOOR_PLAN_IMAGE',
  VIDEO_THUMBNAIL_IMAGE: 'VIDEO_THUMBNAIL_IMAGE',

  // TODO: deprecate
  PROPERTY_IMAGE: 'PROPERTY_IMAGE',
};

export const imageDevelopmentCategory = {
  UNCATEGORIZED: 'Uncategorized',
  DEVELOPMENT_IMAGE: 'Development image',
  DEVELOPMENT_BROCHURE: 'Development brochure',
  DEVELOPMENT_VIDEO: 'Development video',
};

export const imageFlatCategory = {
  FLAT_IMAGE: 'Flat image',
  PROPERTY_IMAGE: 'Property image',
  FLOOR_PLAN_IMAGE: 'Floor plan image',
};

export interface FlatImage extends OrgFile {
  flat_id: number;
  srcUrl: string;
  originalName: string;
  description: string;
  src_thumbnail_url?: string;
}
export interface DevelopmentImage extends OrgFile {
  development_id: number;
  src_thumbnail_url?: string;
}
export interface DevelopmentVideo extends DevelopmentImage {
  orgimages_id_thumbnail: string;
  orgimages_id_video: string;
}
export interface ExtraVideoData extends OrgFile {
  development_id: number;
  src_video_url: string;
}

export type ImageData = FlatImage | DevelopmentImage;

export type ImageEntry = [string, ImageData];

export type ImageEntries = ImageEntry[];

export type MediaData = ImageData & ExtraVideoData;

export type MediaEntry = [string, MediaData];

export type MediaEntries = MediaEntry[];
export interface Bills {
  id: number;
  name: string;
}
export interface VideoData {
  video: OrgFile;
  thumbnail?: OrgFile | undefined;
}

export interface FlatDetails {
  rent_pcm: number;
  flat_category: FlatDetailsCategory;
}
