import postcodeToAreaRegion from './postcodeToAreaRegion.json';

export interface CityAreaRegion {
  city: string;
  area: string;
  region: string;
}

const londonRegions = ['London', 'Greater London'];

// The prefix has 2 characters, and the 2nd letter can either be a letter or a
// number.
const POSTCODE_PREFIX_REGEX = /[A-Z][A-Z]?/;

/**
 * Given the first 2 letters of a postcode, return the city, area, and region.
 *
 * If the postcode is invalid, return an object with empty strings for city,
 * area, and region.
 */
export default function inferCityAreaRegionFromPostcode(
  postcode: string
): CityAreaRegion {
  const postcodePrefixMatch = (postcode ?? '')
    .toUpperCase()
    .match(POSTCODE_PREFIX_REGEX);

  if (!postcodePrefixMatch) {
    return {
      city: '',
      area: '',
      region: '',
    };
  }

  const areaRegion = postcodeToAreaRegion[postcodePrefixMatch[0]] as Pick<
    CityAreaRegion,
    'area' | 'region'
  >;

  if (areaRegion) {
    const city = londonRegions.includes(areaRegion.region)
      ? 'London'
      : areaRegion.area;

    return {
      city,
      ...areaRegion,
    };
  }

  return {
    city: '',
    area: '',
    region: '',
  };
}
